
/*
 *  This file app.component.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from "@angular/router";
import { AuthenticateUserComponent } from './global/components/authenticate-user/authenticate-user.component';
import { SharedService } from './global/services/shared.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from './global/services/auth-service';
import { TLoginTokenResponse } from './global/models/common.models';
import { Store } from '@ngrx/store';
import { loadGlboalStateFromStorage } from './store/global/global.action';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  isLoggedIn = !!JSON.parse(localStorage.getItem('authlogin'));
  mobileDisclaimerShown = false;
  isMobile = true;
  showRedirectDialogForTeams = false;
  flashLoader = "/assets/images/init-loader-1.svg";
  flashLoaderGT = "assets/images/dynamic_images/loader-2.svg";
  @ViewChild('reauth') reauth: AuthenticateUserComponent
  title = 'app';
  public isShowingRouteLoadIndicator: boolean;
  isPageLoading: boolean;
  isTimeOut: boolean;
  isClientConfig = true;
  constructor(
    public router: Router,
    public _shared: SharedService,
    private titleService: Title,
    private _auth: AuthService,
    private store : Store,
  ) {
    window.addEventListener('online', () => {
      this._shared._httpService.isOnline = true;
    });
    window.addEventListener('offline', () => {
      this._shared._httpService.isOnline = false;
    });
    const isGTInstance = this._shared.isDeployedOnGT;
    localStorage.removeItem('analyticsReportAccessType');
    localStorage.removeItem('isRefreshingToken');
    localStorage.setItem('clientConfig', JSON.stringify(isGTInstance));
    localStorage.setItem('showMoveToArchieve', JSON.stringify(true));
    if (isGTInstance) {
      this.isClientConfig = JSON.parse(localStorage.getItem('clientConfig'));
    } else {
      this.isClientConfig = false
    }
    // no need to add this check as the first check already checks for presence of authlogin
   this._auth.isAuthenticatedOrSSO()  && this._auth.getRefreshToken(true).subscribe({
      next:(auth:TLoginTokenResponse)=>{
        this._auth.setLoginAuth(auth);
        this.store.dispatch(loadGlboalStateFromStorage());
      },
      error:(error)=>{
        (error.status === 400 ||  error.status === 5999) && this._shared._httpService.isOnline &&  window.location.pathname.includes("app") &&  this._auth.logout();
      }
    });

  }



  ngOnInit() {
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href", this._shared.companyDetails.favicon);
    this.setTitle();
    this._shared.getAppVersion();
    this.router.events.subscribe(event => {
      this.navigationInterceptor(event);
    });

    this.getClientDomainValues();
  }


  ngAfterViewInit() {
    this.listenForLogout();
  }

  listenForLogout() {

    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage && event.key !== '_grecaptcha') {
        const token = localStorage.getItem('authlogin');

        if (!token) {
          if (event.key !== 'upcomingLinkVerified') {
            this._shared._httpService.updateSignalRAuthTokenSubject.next({ token: null, isLogout: true })
            this.router.navigate(['/']);
          }
          this.isLoggedIn = false;
        }
      }
    }, false);
  }


  navigationInterceptor(event): void {
    if (!localStorage.getItem('authlogin')) {
      if (event instanceof NavigationCancel) {
        localStorage.setItem('NAVIGATIONKEY', event.url);
      }
    }


    if (event instanceof NavigationStart) {
      const path = event.url ? event.url.toLowerCase() : '';
      const flag = path.includes('app/report/detail') || path.includes('app/payroll/processsalary')
        || path.includes('login') || path.includes('app/processattendance')
        || path.includes('app/candidate-list');
      this._shared.profile = event.url.split('/')[2];
      if (flag) {
        this.isPageLoading = true;
      }
    }
    if (event instanceof NavigationEnd || event instanceof NavigationCancel ||
      event instanceof NavigationError) {
      this.isPageLoading = false;
    }

  }

  getClientDomainValues() {
    const hd = localStorage.getItem('hashDomain');
    if (hd) {
      this._shared.getComapanyDetails(hd);
    } else {
      this._shared.getDefaultCompDetails();
    }

  }
  public setTitle() {
    this.titleService.setTitle(this._shared.companyDetails.title);
  }

  isMobileViewNew(isMobile: boolean): void {
    this.isMobile = isMobile;
    this.showRedirectDialogForTeams = !this._shared.isDeployedOnGT && isMobile;
  }

  closeMe(): void {
    this.mobileDisclaimerShown = true
  }
}
